/* eslint-disable react/prop-types */
import 'moment-timezone';

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import moment from 'moment';
import ReactMoment from 'react-moment';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Link as MuiLink,
  Button,
  Divider,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { getConferenceImage } from '../utils';
import { withLayout } from '../components/Layout';
import SEO from '../components/SEO';
import EventDescription from '../components/EventDescription';
import ConferenceRegistrationForm from '../components/ConferenceRegistrationForm';
import QuestionAnswersForm from '../components/QuestionAnswersForm';
import ShareButtons from '../components/ShareButtons';

const useStyles = makeStyles((theme) => ({
  cover: (conference) => ({
    color: theme.palette.text.white,
    textShadow: '1px 1px 10px rgba(0, 0, 0, 0.25)',
    background: `linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(${getConferenceImage(
      conference,
      'imageCover'
    )})`,
    backgroundSize: 'cover, cover',
    backgroundPosition: 'center, center',
    padding: `${theme.spacing(24)}px ${theme.spacing(4)}px`,
    marginTop: theme.spacing(-6),
    marginBottom: theme.spacing(4),
    marginLeft: -1 * theme.spacing(2),
    marginRight: -1 * theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginLeft: -1 * theme.spacing(3),
      marginRight: -1 * theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(-2),
      marginLeft: 0,
      marginRight: 0,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: theme.spacing(8),
    },
  }),
}));

const Page = ({ pageContext }) => {
  const { conference, event } = pageContext;
  const { series, registrationEnabled, qaEnabled } = conference;

  const classes = useStyles(conference);

  const { title } = conference;

  return (
    <>
      <SEO
        title={title}
        cardType="summary_large_image"
        image={getConferenceImage(conference, 'imageUnfurl')}
        description={`${moment(conference.startDate).format(
          'MMMM Do'
        )} — ${moment(conference.endDate).format(
          'MMMM Do, YYYY'
        )}, hosted by River of Life Christian Fellowship in Loveland, Colorado.`}
      />

      <Box className={classes.cover}>
        <Typography variant="h1">{title}</Typography>
        <Typography variant="subtitle1">
          <ReactMoment format="MMMM D">{conference.startDate}</ReactMoment>{' '}
          &mdash;{' '}
          <ReactMoment format="MMMM D">{conference.endDate}</ReactMoment>
          {', '}
          <ReactMoment format="YYYY">{conference.endDate}</ReactMoment>
        </Typography>
      </Box>

      <Typography variant="body1" component="div">
        {series && (
          <>
            <Box mb={2}>
              <Typography variant="h3" component="h2">
                Series: {series.title}
              </Typography>
            </Box>

            <Box mb={6}>
              <Button
                component={Link}
                to={`/media/series/${series.slug}`}
                color="secondary"
                endIcon={<ChevronRightIcon />}
              >
                Watch all sermons
              </Button>
            </Box>

            <Box mb={6}>
              <Divider />
            </Box>
          </>
        )}

        {(event.location || event.description) && (
          <Box mb={2}>
            <Typography variant="h3">Event Description</Typography>
          </Box>
        )}

        {event.location && (
          <Box mb={4}>
            <Box>
              <strong>Location:</strong>{' '}
              <MuiLink href={`https://maps.google.com/?q=${event.location}`}>
                {event.location}
              </MuiLink>
            </Box>
          </Box>
        )}

        {event.description && (
          <Box mb={4}>
            <EventDescription event={event} />
          </Box>
        )}

        {registrationEnabled && (
          <Box mt={-4} mb={8}>
            <Box mb={2}>
              <Typography variant="h3">Conference Registration</Typography>
            </Box>

            <Box mb={2}>
              <Typography variant="body1">
                <em>
                  (Registration is requested for in-person attendees only; no
                  registration is needed for online attendees.)
                </em>
              </Typography>
            </Box>

            <ConferenceRegistrationForm conferenceId={conference.id} />
          </Box>
        )}

        {qaEnabled && (
          <Box mt={-4} mb={8}>
            <Box mb={2}>
              <Typography variant="h3">Questions & Answers</Typography>
            </Box>

            <Box mb={2}>
              <Typography variant="body1">
                This conference will include a Question & Answer session.
                Attendees are invited to submit questions pertaining to the
                spiritual life. During this session, a selection of submitted
                questions will be answered live for the broader group of
                attendees or viewers (and most likely recorded and posted
                online). Your name, location, and contact information is
                optional and will not be mentioned publicly.
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography variant="body1">
                Please also consider searching for previous Q&A answers on the{' '}
                <MuiLink href="https://cfcindia.com/qa">
                  CFC India website
                </MuiLink>
                .
              </Typography>
            </Box>

            <QuestionAnswersForm conferenceId={conference.id} />
          </Box>
        )}

        <Box mb={6}>
          <Button
            component={Link}
            to="/events/calendar"
            color="secondary"
            endIcon={<ChevronRightIcon />}
          >
            See full calendar
          </Button>
        </Box>

        <ShareButtons title={title} />
      </Typography>
    </>
  );
};

Page.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default withLayout(Page, { container: 'md' });
