import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { useFormikContext } from 'formik';

const ValidatedField = ({ name, label, children, ...rest }) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
  } = useFormikContext();

  const invalid = touched[name] && errors[name];

  return (
    <TextField
      variant="outlined"
      name={name}
      label={label}
      fullWidth
      value={values[name]}
      error={invalid}
      helperText={invalid ? errors[name] : null}
      onChange={handleChange}
      onBlur={handleBlur}
      {...rest}
    >
      {children}
    </TextField>
  );
};

ValidatedField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
};

ValidatedField.defaultProps = {
  children: null,
};

export default ValidatedField;
