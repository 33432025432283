import React from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Box, MenuItem, Button, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import ValidatedField from './ValidatedField';

export const SUBMIT_CONFERENCE_REGISTRATION = gql`
  mutation SubmitConferenceRegistration(
    $attendance: Attendance!
    $givenName: String!
    $familyName: String!
    $locality: String!
    $partySize: Int!
    $email: String!
    $conferenceId: String!
  ) {
    submitConferenceRegistration(
      attendance: $attendance
      givenName: $givenName
      familyName: $familyName
      locality: $locality
      partySize: $partySize
      email: $email
      conferenceId: $conferenceId
    ) {
      conferenceRegistration {
        id
      }
    }
  }
`;

const ConferenceRegistrationForm = ({ conferenceId }) => {
  const [submitConferenceRegistration, { data, loading }] = useMutation(
    SUBMIT_CONFERENCE_REGISTRATION
  );

  if (data) {
    return (
      <Alert severity="info">
        Your registration has been received. Thank you!
      </Alert>
    );
  }

  return (
    <Formik
      initialValues={{
        attendance: '',
        givenName: '',
        familyName: '',
        locality: '',
        partySize: '1',
        email: '',
      }}
      validationSchema={yup.object().shape({
        attendance: yup.string().required('Attendance selection is required'),
        givenName: yup.string().required('First name is required'),
        familyName: yup.string().required('Last name is required'),
        locality: yup.string().required('Locality is required'),
        partySize: yup.number().positive().integer().required(),
        email: yup.string().email('Must be a valid email address').required(),
      })}
      isInitialValid={() => false}
      onSubmit={(values) =>
        submitConferenceRegistration({
          variables: {
            conferenceId,
            ...values,
          },
        })
      }
    >
      {({ isValid }) => (
        <Form>
          <Box mb={2}>
            <ValidatedField select name="attendance" label="Attendance *">
              <MenuItem value="IN_PERSON">In Person</MenuItem>
              <MenuItem value="ONLINE">Online Only</MenuItem>
            </ValidatedField>
          </Box>

          <Box mb={2}>
            <ValidatedField
              type="text"
              name="givenName"
              label="First Name *"
              placeholder="Primary contact first name"
            />
          </Box>

          <Box mb={2}>
            <ValidatedField
              type="text"
              name="familyName"
              label="Last Name *"
              placeholder="Primary contact last name"
            />
          </Box>

          <Box mb={2}>
            <ValidatedField
              type="text"
              name="locality"
              label="Location *"
              placeholder="Primary contact locality, eg. Loveland, Colorado"
            />
          </Box>

          <Box mb={2}>
            <ValidatedField select name="partySize" label="Party Size *">
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="6">6</MenuItem>
              <MenuItem value="7">7</MenuItem>
              <MenuItem value="8">8</MenuItem>
              <MenuItem value="9">9</MenuItem>
              <MenuItem value="10">10</MenuItem>
              <MenuItem value="11">11</MenuItem>
              <MenuItem value="12">12</MenuItem>
              <MenuItem value="13">13</MenuItem>
              <MenuItem value="14">14</MenuItem>
              <MenuItem value="15">15</MenuItem>
              <MenuItem value="16">16</MenuItem>
              <MenuItem value="17">17</MenuItem>
              <MenuItem value="18">18</MenuItem>
              <MenuItem value="19">19</MenuItem>
              <MenuItem value="20">20</MenuItem>
            </ValidatedField>
          </Box>

          <Box mb={2}>
            <ValidatedField
              type="text"
              name="email"
              label="Email *"
              placeholder="Primary contact email address"
            />
          </Box>

          <Box display="flex">
            <Box mr={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading || !isValid}
              >
                Register
              </Button>
            </Box>

            {loading && <CircularProgress color="secondary" />}
          </Box>
        </Form>
      )}
    </Formik>
  );
};

ConferenceRegistrationForm.propTypes = {
  conferenceId: PropTypes.string,
};

ConferenceRegistrationForm.defaultProps = {
  conferenceId: undefined,
};

export default ConferenceRegistrationForm;
