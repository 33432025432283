import React from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Box, MenuItem, Button, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import ValidatedField from './ValidatedField';

export const SUBMIT_QUESTION = gql`
  mutation SubmitQuestion(
    $category: Category!
    $text: String!
    $name: String
    $location: String
    $email: String
    $conferenceId: String
  ) {
    submitQuestion(
      category: $category
      text: $text
      name: $name
      location: $location
      email: $email
      conferenceId: $conferenceId
    ) {
      question {
        id
      }
    }
  }
`;

const QuestionAnswerForm = ({ conferenceId }) => {
  const [submitQuestion, { data, loading }] = useMutation(SUBMIT_QUESTION);

  if (data) {
    return (
      <Alert severity="info">Your question has been received. Thank you!</Alert>
    );
  }

  return (
    <Formik
      initialValues={{
        category: '',
        text: '',
        name: '',
        location: '',
        email: '',
      }}
      validationSchema={yup.object().shape({
        category: yup.string().required('Category is required'),
        text: yup
          .string()
          .max(255, 'Questions must be 255 characters or fewer')
          .required('Question is required'),
        name: yup.string(),
        location: yup.string(),
        email: yup.string().email('Must be a valid email address'),
      })}
      isInitialValid={() => false}
      onSubmit={(values) =>
        submitQuestion({
          variables: {
            conferenceId,
            ...values,
          },
        })
      }
    >
      {({ isValid }) => (
        <Form>
          <Box mb={2}>
            <ValidatedField select name="category" label="Category *">
              <MenuItem value="SPIRITUAL_GROWTH">Spiritual Growth</MenuItem>
              <MenuItem value="PERSONAL_DISCIPLINE">
                Personal Discipline
              </MenuItem>
              <MenuItem value="FAMILY_LIFE">Family Life</MenuItem>
              <MenuItem value="CHURCH_LIFE">Church Life</MenuItem>
              <MenuItem value="SOCIAL_LIFE">Social & Work Life</MenuItem>
              <MenuItem value="INFORMATION">Request for Information</MenuItem>
            </ValidatedField>
          </Box>

          <Box mb={2}>
            <ValidatedField
              type="text"
              name="text"
              label="Question *"
              placeholder="Maximum 255 characters"
              multiline
            />
          </Box>

          <Box mb={2}>
            <ValidatedField
              type="text"
              name="name"
              label="Name"
              placeholder="First and last name (optional / confidential)"
            />
          </Box>

          <Box mb={2}>
            <ValidatedField
              type="text"
              name="location"
              label="Location"
              placeholder="City, region, and/or country (optional / confidential)"
            />
          </Box>

          <Box mb={2}>
            <ValidatedField
              type="text"
              name="email"
              label="Email"
              placeholder="Contact email address (optional / confidential)"
            />
          </Box>

          <Box display="flex">
            <Box mr={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading || !isValid}
              >
                Submit Question
              </Button>
            </Box>

            {loading && <CircularProgress color="secondary" />}
          </Box>
        </Form>
      )}
    </Formik>
  );
};

QuestionAnswerForm.propTypes = {
  conferenceId: PropTypes.string,
};

QuestionAnswerForm.defaultProps = {
  conferenceId: undefined,
};

export default QuestionAnswerForm;
